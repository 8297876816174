import { homePage } from "./Pages/home-page";
import { gamePage } from "./Pages/game";
import Experience from "../Experience/Experience";
import { MmorpgHttp } from "../Utils/Mmorpg-Http";
import { GameUi } from "./GameUi";

export class Main {
  constructor() {
    this.mmorpgHttp = new MmorpgHttp();
    this.gameUi = new GameUi(this.mmorpgHttp);
    this.mmorpgHttp.httpInit();
    this.pageLoad();
  }
  guest = false;
  signInConfirm = false;

  async pageLoad(options) {
    this.guest = options?.guest === true ? true : false;

    // Check for valid jwtToken
    try {
      const response = await this.mmorpgHttp.checkJwt();
      if (response?.data?.message === "success") {
        this.signInConfirm = true;
      }
    } catch (error) {
      console.log(error);
    }

    if (this.signInConfirm || this.guest === true) {
      // Renders game
      this.renderPage(gamePage);
      this.gameUi.initialAccountLoad();
      this.gameUi.quickBarLoad();
      this.gameUi.initQuickBarListeners();
      this.gameUi.initSettingsListener();
      new Experience(document.querySelector("canvas.webgl"));
    } else {
      //Renders sign-in page
      this.renderPage(homePage);
      this.initEvents();
    }
  }

  renderPage(page) {
    const mainContent = document.getElementById("main");
    mainContent.insertAdjacentHTML("afterbegin", page);
  }

  initEvents() {
    let formData = document.getElementById("submitAuth");
    let guestButton = document.getElementById("guest");

    formData.addEventListener("click", async () => {
      const res = await this.mmorpgHttp.postSignIn();
      const message = res.data.message;

      if (message === "success") {
        this.pageLoad();
      }
    });

    guestButton.addEventListener("click", () => {
      this.pageLoad({ guest: true });
    });
  }
}
