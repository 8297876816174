/* eslint no-unused-vars: "off" */
import { createSlice } from "@reduxjs/toolkit";

export const accountItemsSlice = createSlice({
  name: "accountItems",
  initialState: {
    allAccountItems: [],
  },
  reducers: {
    increment: (state) => {
      state += 1;
    },
    decrement: (state) => {
      state -= 1;
    },
    incrementByAmount: (state, action) => {
      state += action.payload;
    },
    fetchAllItems: (state, action) => {
      state.allAccountItems = action.payload;
    },
  },
});

//destructure the actions
export const { increment, decrement, incrementByAmount, fetchAllItems } =
  accountItemsSlice.actions;

//get the value of the state
export const stateAllAccountItems = (state) => {
  return state.accountItems.allAccountItems;
};
