import "../Ui/ui.css";
import { store } from "../Store/store";

export class GameUi {
  constructor(mmorpgHttp) {
    this.mmorpgHttp = mmorpgHttp;
    store.subscribe(this.quickBarLoad); //listens for changes to the store and fires quickBarLoad after change
  }

  initialAccountLoad() {
    store.dispatch(this.mmorpgHttp.getAllAccountItems());
  }

  quickBarLoad() {
    // const actionOne = document.getElementById("actionOne");
    const actions = document.getElementsByClassName("action");
    const bagItems = document.getElementsByClassName("inventoryItem");
    let accountItems = [];

    const state = store.getState();

    accountItems = state?.accountItems?.allAccountItems ? state?.accountItems?.allAccountItems : [];
    accountItems.forEach((item) => {
      const itemId = item.account_item_item_id;
      const itemQuickBarNum = item.account_item_quickbar;
      const itemBagNum = item.account_item_bag;
      // console.log(item.account_item_bag)
      bagItems[itemBagNum].style.backgroundImage =
        `url('./ui/Items/${itemId}.png'), url("./ui/Frames/Frame_Stone_01.png")`;
      actions[itemQuickBarNum].style.backgroundImage =
        `url('./ui/Items/${itemId}.png'), url("./ui/Frames/Frame_Stone_01.png")`;
    });
  }

  initQuickBarListeners() {
    const actionOne = document.getElementById("actionOne");

    actionOne.addEventListener("click", () => {
      const state = store.getState();
      console.log(state);
    });
  }

  initSettingsListener() {
    const charSettingsElement = document.getElementById("settingOne");
    const craftingSettingsElement = document.getElementById("settingTwo");
    const bagsSettingsElement = document.getElementById("settingThree");
    const settingsElement = document.getElementById("settingFour");
    const inventoryElement = document.getElementById("inventory");

    charSettingsElement.addEventListener("click", () => {
      console.log("Character Screen Setting Clicked");
    });

    craftingSettingsElement.addEventListener("click", () => {
      console.log("Crafting Screen Setting Clicked");
    });

    bagsSettingsElement.addEventListener("click", () => {
      this.showInventory();
    });

    settingsElement.addEventListener("click", () => {
      console.log("Setting screen setting clicked");
    });

    inventoryElement.addEventListener("click", () => {
      this.hideInventory();
    });
  }

  showInventory() {
    const inventoryElement = document.getElementById("inventory");

    inventoryElement.style.display = "grid";
  }

  hideInventory() {
    const inventoryElement = document.getElementById("inventory");

    inventoryElement.style.display = "none";
  }
}
