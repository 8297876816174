import axios from "axios";
import { fetchAllItems } from "../Store/Account/accountItemsSlice";

export class MmorpgHttp {
  constructor() {}
  // authInstance hits the auth endpoint microservice
  authInstance = axios.create({
    baseURL: "https://backend-mmorpg.codecody.dev/auth/",
  });
  mmorpgBackendInstance = axios.create({
    baseURL: "https://backend-mmorpg.codecody.dev",
  });
  /* eslint no-undef: "off" */
  environment = webPackEnvironment; // declared and defined in webpack config files
  jwtToken = "Bearer" + " " + localStorage.getItem("jwt");

  httpInit() {
    // setup for development environment
    if (this.environment === "development") {
      this.authInstance.defaults.baseURL = "http://localhost:4203/auth/";
      this.mmorpgBackendInstance.defaults.baseURL = "http://localhost:4202/";
    }
  }

  async postSignIn() {
    let response;
    const emailInput = document.getElementById("email");
    const passwordInput = document.getElementById("password");
    const body = { email: emailInput.value, password: passwordInput.value };

    try {
      response = await this.authInstance.request({
        method: "post",
        url: "sign-in/",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      });
    } catch (error) {
      console.log(error);
    }
    if (response.data.message === "success" && response.data.body.token) {
      localStorage.setItem("jwt", response.data.body.token); //saves jwt token to localstorage;
      this.jwtToken = "Bearer" + " " + response.data.body.token;
    }

    return response;
  }

  async checkJwt() {
    let response;

    try {
      response = await this.authInstance.request({
        method: "get",
        url: "jwt/check/",
        withCredentials: true,
        headers: {
          authorization: this.jwtToken,
        },
      });
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  getAllAccountItems = () => {
    return async (dispatch) => {
      let response;

      try {
        response = await this.mmorpgBackendInstance.request({
          method: "get",
          url: "account-items",
          headers: {
            authorization: this.jwtToken,
          },
        });
      } catch (error) {
        console.log(error);
      }

      dispatch(fetchAllItems(response?.data?.body?.allAccountItems));
    };
  };
}
