export default [
  {
    name: "environmentMapTexture",
    type: "cubeTexture",
    path: [
      "textures/environmentMap/px.jpg",
      "textures/environmentMap/nx.jpg",
      "textures/environmentMap/py.jpg",
      "textures/environmentMap/ny.jpg",
      "textures/environmentMap/pz.jpg",
      "textures/environmentMap/nz.jpg",
    ],
  },
  {
    name: "groundDirtStones002",
    type: "texture",
    path: "textures/ground-dirt-rocky002/GroundDirtRocky002_COL_1K.jpg",
  },
  {
    name: "dragonModel",
    type: "gltfModel",
    path: "models/dragon/Dragon.gltf",
  },
  {
    name: "chest01",
    type: "gltfModel",
    path: "models/chests/Chest01.gltf",
  },
  //environment
  {
    name: "rock01",
    type: "gltfModel",
    path: "models/environment/rocks/rock01.glb",
  },
  {
    name: "tree01",
    type: "gltfModel",
    path: "models/environment/trees/tree01.glb",
  },
  {
    name: "grass01",
    type: "gltfModel",
    path: "models/environment/grass/grass01.glb",
  },
  {
    name: "treeHouse",
    type: "gltfModel",
    path: "models/environment/building/tree-house.gltf",
  },
  //player
  {
    name: "sword01",
    type: "gltfModel",
    path: "models/items/weapons/sword01.gltf",
  },
  {
    name: "testPaladin",
    type: "gltfModel",
    path: "models/npc/testPaladin.gltf",
  },
  {
    name: "player",
    type: "gltfModel",
    path: "models/player/player-01.gltf",
  },
  //npc
  {
    name: "druid",
    type: "gltfModel",
    path: "models/npc/druid.gltf",
  },
];
